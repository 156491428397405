import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { LoaderComponent } from './components/loader/loader.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

// services
import { ChatService } from './services/chat.service';
import { CustomizerService } from './services/customizer.service';
import { NavService } from './services/nav.service';
import { TableService } from './services/table.service';
// Directives
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BalancesComponent } from './components/balances/balances.component';
import { InfoComponent } from './components/info/info.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { PascalCaseAddSpacesPipe } from './pipes/pascal-case-add-spaces.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    RightSidebarComponent,
    BalancesComponent,
    InfoComponent,
    NotificationsComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    BreadcrumbComponent,
    CustomizerComponent,
    PascalCaseAddSpacesPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    GalleryModule,
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    NgbdSortableHeader,
    InfoComponent,
    PascalCaseAddSpacesPipe,
  ],
  providers: [
    NavService,
    ChatService,
    CustomizerService,
    TableService,
    NgbModule,
  ],
})
export class SharedModule {}
