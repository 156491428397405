import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  toggleInfo: boolean = false;

  constructor(public router: Router) {}

  ngOnInit() {}

  onInfoClick() {
    this.toggleInfo = !this.toggleInfo;
  }
}
