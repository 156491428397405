<ul class="notification-dropdown onhover-show-div">
  <li>
    Notifications
    <span class="badge rounded-pill badge-primary pull-right">{{
      notifications.length
    }}</span>
  </li>
  <span *ngFor="let notification of notifications">
    <li>
      <div class="media">
        <div class="flex-grow-1">
          <h6
            class="mt-0"
            [ngClass]="{
              'txt-success': notification.type === 'Success',
              'txt-danger': notification.type === 'Error',
              'txt-warning': notification.type === 'Warning'
            }"
          >
            <span *ngIf="notification.type === 'Success'">
              <app-feather-icons
                class="download-color font-success"
                [icon]="'check-circle'"
              ></app-feather-icons>
            </span>
            <span *ngIf="notification.type === 'Error'">
              <app-feather-icons
                class="alert-color font-danger"
                [icon]="'alert-octagon'"
              ></app-feather-icons>
            </span>
            <span *ngIf="notification.type === 'Warning'">
              <app-feather-icons
                class="font-warning"
                [icon]="'alert-triangle'"
              ></app-feather-icons>
            </span>
            <span *ngIf="notification.type === 'Info'">
              <app-feather-icons
                class="shopping-color"
                [icon]="'alert-circle'"
              ></app-feather-icons> </span
            >{{ notification.event | pascalCaseAddSpaces
            }}<small class="pull-right">{{
              notification.createdOn | date : "shortTime"
            }}</small>
          </h6>
          <p class="mb-0">{{ notification.message }}</p>
        </div>
      </div>
    </li>
  </span>
  <li class="bg-light txt-dark"></li>
</ul>
