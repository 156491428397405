<div class="pull-right">
  <div class="row">
    <div class="col">
      <h3 class="d-flex align-items-center">
        pxP&nbsp;
        <span class="badge rounded-pill badge-light text-dark ml-2">{{
          pxp | number : "1.4-4"
        }}</span>
      </h3>
    </div>
  </div>
</div>
