import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  psApiBaseUrl = environment.psApiBaseUrl;

  constructor() {}

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
  }
}
