import { Component, OnInit } from '@angular/core';
import { PlayerService } from 'src/app/services/player.service';
import { BalancesService } from '../../services/balances.service';

@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss'],
})
export class BalancesComponent implements OnInit {
  pxp = 0.0;

  constructor(
    private playerService: PlayerService,
    private balancesService: BalancesService
  ) {
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.playerService.getPlayer(userId).subscribe((player) => {
        this.pxp = player.pixelPoints;
      });
    }

    this.balancesService.currentPxp.subscribe((pxp) => (this.pxp = pxp));
  }

  ngOnInit() {}
}
