import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  psApiBaseUrl = environment.psApiBaseUrl;

  constructor(private http: HttpClient) {}

  getMostRecent(count: number = 5): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('count', count);
    return this.http.get<any>(`${this.psApiBaseUrl}/notifications`, {
      params: queryParams,
    });
  }
}
