import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, of } from 'rxjs';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public errorMessage: string;

  constructor(
    private fb: FormBuilder,
    public tokenService: TokenService,
    public router: Router,
    private toastrService: ToastrService
  ) {
    this.loginForm = this.fb.group({
      userId: ['', [Validators.required, this.exactLengthValidator(36)]],
    });
  }

  ngOnInit() {}

  login() {
    const userId = this.loginForm.value['userId'];
    localStorage.setItem('userId', userId);

    // Get token using the userId and store it in local storage
    this.tokenService
      .getToken(userId)
      .pipe(
        map((response) => {
          if (response.status === 200) {
            localStorage.setItem('accessToken', response.body.token);
            this.router.navigate(['/']);
          } else {
            this.toastrService.error('Login failed!', 'Error', {
              closeButton: true,
            });
            this.loginForm.reset();
          }
        }),
        catchError((error, caught) => {
          this.toastrService.error('Login failed!', 'Error', {
            closeButton: true,
          });
          this.loginForm.reset();
          return of(null);
        })
      )
      .subscribe();
  }

  private exactLengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isExactLength = control.value?.length === length;
      return isExactLength ? null : { exactLength: { value: control.value } };
    };
  }
}
