import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications: any[] = [];

  $notifications: Observable<any[]> = this.notificationsService
    .getMostRecent()
    .pipe(map((notifications) => notifications.items));

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit() {
    this.$notifications.subscribe((notifications) => {
      const now = new Date();

      // Filter to only include notifications from the past 24 hours
      this.notifications = notifications.filter((notification) => {
        const createdOn = new Date(notification.createdOn);
        const diffInHours =
          (now.getTime() - createdOn.getTime()) / 1000 / 60 / 60;

        return diffInHours <= 24;
      });
    });
  }
}
