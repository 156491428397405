export class ConfigDB {
  static data: any = {
    settings: {
      layout_type: 'ltr',
      sidebar: {
        type: 'compact-wrapper',
        body_type: 'sidebar-icon',
      },
      sidebar_setting: 'border-sidebar',
      sidebar_backround: 'dark-sidebar',
    },
    color: {
      layout_version: '',
      color: '',
      primary_color: '',
      secondary_color: '',
      mix_layout: 'dark-only',
    },
    router_animation: 'fadeIn',
  };
}
