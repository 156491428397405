<div class="row mb-4">
  <div class="col-xl-12">
    <div class="common-flex">
      <button class="btn btn-warning" type="button" (click)="onInfoClick()">
        <i class="fa fa-question-circle"></i> Info
      </button>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="collapse collapse-horizontal mt-3"
          id="infoCard"
          [class.show]="toggleInfo"
        >
          <div class="card card-body bg-secondary-light accordion-h-space mb-0">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
