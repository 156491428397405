<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">
        Copyright {{ year }} © ExitCode Studio | All rights reserved
      </p>
    </div>

    <div class="col-md-6">
      <p class="pull-right mb-0">
        One -|<i class="fa fa-heart"></i> |- one take
      </p>
    </div>
  </div>
</div>
