import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Add access token to the request
    const token = localStorage.getItem('accessToken');
    if (token) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    // Execute the request
    return next.handle(request).pipe(
      tap({
        next: (event) => {},
        error: (error: any) => {
          // If the request is not /token endpoint and returns a 401 status code
          // Then log the user out, and redirect to the login page
          if (
            error instanceof HttpErrorResponse &&
            error.status === 401 &&
            !request.url.endsWith('/token')
          ) {
            this.sessionService.logout();
            this.toastrService.warning(
              'Session expired, please login again',
              'Warning',
              {
                closeButton: true,
              }
            );
            this.router.navigate(['/auth/login']);
          }
        },
      })
    );
  }
}
