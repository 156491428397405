import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  psApiBaseUrl = environment.psApiBaseUrl;

  constructor(private http: HttpClient) {}

  getToken(id: string): Observable<any> {
    return this.http.post<any>(
      `${this.psApiBaseUrl}/token`,
      { userId: id },
      { observe: "response" }
    );
  }
}
