<!-- Page Header Start-->
<div
  class="page-main-header"
  [ngClass]="{ open: navServices.collapseSidebar }"
  id="page-main-header"
>
  <div class="main-header-right row">
    <div class="main-header-left col-auto p-0 d-lg-none">
      <div class="logo-wrapper">
        <a routerLink="/"
          ><img src="assets/images/pixel-sm-logo.png" alt=""
        /></a>
      </div>
    </div>
    <div class="mobile-sidebar col-auto p-0">
      <div class="media-body text-start switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons
              id="sidebar-toggle"
              [icon]="'align-left'"
            ></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{ open: openNav }">
        <li></li>
        <li>
          <a
            href="javascript:void(0)"
            class="text-dark"
            (click)="toggleFullScreen()"
          >
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li>
        <li class="onhover-dropdown">
          <app-feather-icons [icon]="'bell'"></app-feather-icons
          ><span class="dot"></span>
          <app-notifications></app-notifications>
        </li>
        <li class="onhover-dropdown">
          <div class="media align-items-center">
            <img
              class="align-self-center pull-right img-50 rounded-circle"
              src="assets/images/user/user.png"
              alt="header-user"
            />
            <div class="dotted-animation">
              <span class="animate-circle"></span
              ><span class="main-circle"></span>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div p-20">
            <li class="mb-2">
              <a routerLink="/profile">
                <app-feather-icons [icon]="'user'"></app-feather-icons>Profile
              </a>
            </li>
            <li class="logout">
              <a (click)="signOut()">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
