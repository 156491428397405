import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastrService: ToastrService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Execute the request
    return next.handle(request).pipe(
      tap({
        next: (event) => {},
        error: (error: any) => {
          // If the request returns a 401 status code, log the user out, and redirect to the login page
          if (error instanceof HttpErrorResponse) {
            if (
              error.status >= 400 &&
              error.status < 500 &&
              error.status !== 401
            ) {
              this.toastrService.warning(
                error.error.message || 'An error occurred',
                'Warning',
                {
                  closeButton: true,
                }
              );
            }
          }
        },
      })
    );
  }
}
