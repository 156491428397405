import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { Observable, catchError, map, of } from 'rxjs';
import { TokenService } from '../../services/token.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private tokenService: TokenService,
    private sessionService: SessionService,
    public router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      this.router.navigate(['/auth/login']);
      return of(false);
    }

    const token = localStorage.getItem('accessToken');
    if (token) {
      if (this.isTokenExpired(token)) {
        this.sessionService.logout();
        this.router.navigate(['/auth/login']);
        return of(false);
      } else {
        return true;
      }
    } else {
      return this.tokenService.getToken(userId).pipe(
        map((response) => {
          if (response.status === 200) {
            localStorage.setItem('accessToken', response.body.token);
            return true;
          } else {
            this.sessionService.logout();
            this.router.navigate(['/auth/login']);
            return false;
          }
        }),
        catchError((error) => {
          // Navigate to login page and return false
          this.sessionService.logout();
          this.router.navigate(['/auth/login']);
          return of(false);
        })
      );
    }
  }

  private isTokenExpired(token: string): boolean {
    {
      const decodedToken = jwtDecode(token);
      if (!decodedToken.exp) {
        return false;
      }

      const currentDateTime = new Date().getTime() / 1000;
      return decodedToken.exp < currentDateTime;
    }
  }
}
