import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  psApiBaseUrl = environment.psApiBaseUrl;

  constructor(private http: HttpClient) {}

  getPlayer(id: string): Observable<any> {
    return this.http.get<any>(`${this.psApiBaseUrl}/players/${id}`);
  }

  increasePower(id: string, type: string, power: number): Observable<any> {
    return this.http.patch<any>(
      `${this.psApiBaseUrl}/players/${id}/attributes/fragment-power`,
      {
        type: type,
        power: power,
      }
    );
  }

  updateProfile(id: string, nickname: string): Observable<any> {
    return this.http.patch<any>(`${this.psApiBaseUrl}/players/${id}/profile`, {
      nickname: nickname,
    });
  }
}
