<div class="container-fluid p-0">
  <!-- login page start-->
  <div class="authentication-main">
    <div class="row">
      <div class="col-md-12">
        <div class="auth-innerright">
          <div class="authentication-box">
            <div class="text-center">
              <img
                src="assets/images/pixel-logo.png"
                alt="logo"
                width="256"
                height="256"
              />
            </div>
            <div class="card mt-4">
              <div class="card-body">
                <div class="text-center">
                  <h4>LOGIN</h4>
                </div>
                <form class="theme-form" [formGroup]="loginForm">
                  <div class="form-group">
                    <label class="col-form-label pt-0">User ID *</label>
                    <input
                      class="form-control"
                      formControlName="userId"
                      type="text"
                      minlength="36"
                      maxlength="36"
                      required
                    />
                    <div
                      *ngIf="loginForm.controls['userId'].touched && loginForm.controls['userId'].errors?.['required']"
                      class="text text-danger mt-2"
                    >
                      User ID is required
                    </div>
                    <div
                      *ngIf="
                        loginForm.controls['userId'].touched &&
                        !loginForm.controls['userId'].errors?.['required'] &&
                        loginForm.controls['userId'].errors?.['exactLength']
                      "
                      class="text text-danger mt-2"
                    >
                      User ID must be 36 characters
                    </div>
                  </div>
                  <div class="form-group row mt-3 mb-0">
                    <button
                      class="btn btn-primary btn-block"
                      [disabled]="!loginForm.valid"
                      (click)="login()"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- login page end-->
</div>
