import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BalancesService {
  private pxpSource = new BehaviorSubject(0.0);
  private pxtSource = new BehaviorSubject(0);

  currentPxp = this.pxpSource.asObservable();
  currentPxt = this.pxtSource.asObservable();

  constructor() {}

  changePxp(pxp: number) {
    this.pxpSource.next(pxp);
  }

  changePxt(pxt: number) {
    this.pxtSource.next(pxt);
  }
}
